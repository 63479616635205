import {NavLink} from 'react-router-dom';
import {Navbar, Nav} from 'rsuite';

export default function Header(){
  let menu = [
    {name: 'Cães',            key: 13, subitems: [ // e
      {name: 'Golden',                  key: 1, subitems: [ // sub
        {name: 'Raças Pequenas',        key: 2, subitems: [ // nvl2
          {name: 'Adulto Carne',        key: 3, link: '/golden_cao_ad_rpq_carne'}, // nvl3
          {name: 'Adulto Frango',       key: 4, link: '/golden_cao_ad_rpq_frango'},
          {name: 'Filhote Carne',       key: 5, link: '/golden_cao_fil_rpq_carne'},
        ]}
      ]}
    ]},
    {name: 'Gatos',           key: 14, subitems: [
      {name: 'Golden',                  key: 3, link: '#'}
    ]},
    {name: 'Nossas Lojas',              key: 15, subitems: [
      {name: 'Tanque',                  key: 6, link: '/tanque'},
      {name: 'Vila Isabel',             key: 7, link: '/vila'},
      {name: 'Grajau',                  key: 8, link: '/grajau'},
      {name: 'Pechincha',               key: 9, link: '/pechincha'},
      {name: 'Catete',                  key: 10, link: '/catete'},
      {name: 'Jacarepagua',             key: 12, link: '/jacarepagua'},
      {name: 'Tijuca',                  key: 11, link: '/tijuca'}
    ]}
  ];

  const NavRender = ({navElement}) => {
    if(navElement?.subitems?.length > 0){
      return(
        <Nav.Menu title={navElement.name} key={navElement.key}>
          {navElement?.subitems?.map((ele) => (
            <NavRender navElement={ele} key={ele.key}/>
          ))}
        </Nav.Menu>
      )
    }else{
      return(
        <Nav.Item className="navigation-item" key={navElement.key}>
          <NavLink className="header-link" to={navElement.link}>{navElement.name}</NavLink>
        </Nav.Item>
      )
    }
  };

  return(
    <div id="page-header">
      <div id="header-menu">
        <Navbar>
          <Navbar.Brand href="#">Dogão Pet Shop Varejos</Navbar.Brand>
          <Nav className="header-navigation">
            {menu.map((ele1) => (
              <NavRender navElement={ele1} key={ele1.key}/>
            ))}
          </Nav>
        </Navbar>
      </div>
      {/* <nav id="menu-mobile">
        <div id="header-menu">
          <Navbar>
            <Nav className="header-navigation">
              <Nav.Menu title="Menu">
                <Nav.Item className="navigation-item" eventKey="1"><NavLink className="header-link" to="/home">Home</NavLink></Nav.Item>
                {menu_options}
              </Nav.Menu>
            </Nav>
          </Navbar>
        </div>
      </nav> */}
    </div>
  )
}
