import Header from '../components/header.js';
import Footer from '../components/footer.js';

export default function Home() {
  return (
    <>
      <Header />
      <div id="page-content">
        home page
      </div>
      <Footer />
    </>
  );
}