import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Home from './pages/home.js';
import GoldenCaoAdRpqCarne from './pages/golden_cao_ad_rpq_carne.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home"               element={<Home />} />
        <Route path="/golden_cao_ad_rpq_carne"               element={<GoldenCaoAdRpqCarne />} /> */
      </Routes>
    </BrowserRouter>
  );
}

export default App;
