import {NavLink} from 'react-router-dom';
import Header from '../components/header.js';
import Footer from '../components/footer.js';

export default function GoldenCaoAdRpqCarne() {
  const info = {
    descricao: 'Golden Para Cães Adultos De Raças Pequenas',
    sabor: 'Carne',
    fabricante: 'Premier',
    niv_garantia_desc: ['Umidade (máx.)', 'Proteína Bruta (mín.)', 'Extrato Etéreo (mín.)', 'Matéria Mineral (máx.)', 'Matéria Fibrosa (máx.)', 'Cálcio (máx.)', 'Cálcio (mín.)', 'Fósforo (mín.)', 'Sódio (mín.)', 'Potássio (mín.)', 'Ômega 6 (mín.)', 'Ômega 3 (mín.)', 'Energia Metabolizável'],
    niv_garantia_valores: ['10,00% 100 g/kg', '23,00% 230 g/kg', '12,00% 120 g/kg', '8,50% 85 g/kg', '3,00% 30 g/kg', '1,80% 18 g/kg', '1,00% 10 g/kg', '0,70% 7 g/kg', '0,20% 2 g/kg', '0,58% 5,8 g/kg', '2,00% 20 g/kg', '0,22% 2,2 g/kg', '3.795 kcal/kg'],
    composicao: 'Farinha de vísceras de frango, farinha de carne e ossos de bovino, grão de milho*, quirera de arroz, farelo de arroz desengordurado, grão de linhaça, polpa desidratada de beterraba, banha refinada, gordura de frango, cloreto de potássio, cloreto de sódio, ácido propiônico, bentonita, BHA (Butilhidroxianisol), BHT (Butilhidroxitolueno), extrato de yucca (0,04%), hexametafosfato de sódio (0,10%), hidrolisado de fígado de aves e suíno, vitamina A, vitamina B1, vitamina B2, vitamina B3, vitamina B5, vitamina B6, vitamina B7, vitamina B9, vitamina B12, vitamina C, cloreto de colina, vitamina D3, vitamina E, vitamina K3, iodeto de cálcio, selenometionina hidroxi análoga, sulfato de cobre pentahidratado, sulfato de ferro, sulfato de manganês, sulfato de zinco monohidratado. *Contém milho transgênico (espécies doadoras de gene: Bacillus thuringiensis e/ou Agrobacterium tumefaciens e/ou Streptomyces viridochromogenes e/ou Zea mays).',
    embalagens: [
      {peso: '1kg', img_link: 'img/golden-cao-1kg-ad-rpq-carne.webp', shop_link: 'https://www.dogaopet.com.br/cachorros/racoes/racao-seca/golden-cao-1kg-formula-adulto-racas-pequenas-carne'},
      {peso: '3kg', img_link: 'img/golden-cao-3kg-ad-rpq-carne.webp', shop_link: 'https://www.dogaopet.com.br/cachorros/racoes/racao-seca/golden-cao-3kg-formula-adulto-racas-pequenas-carne'},
      {peso: '15kg', img_link: 'img/golden-cao-15kg-ad-rpq-carne.webp', shop_link: 'https://www.dogaopet.com.br/golden-cao-15kg-formula-adulto-racas-pequenas-carne'}]
  }

  let nvs_garantia = info.niv_garantia_desc.map((desc, k) => {
    return(
      <tr key={k}>
        <td>{desc}</td>
        <td>{info.niv_garantia_valores[k]}</td>
      </tr>
    );
  });

  let embalagens = info.embalagens.map((emb, k) =>{
    return(
      <div key={k} className="embalagem-content">
        {emb.peso}
        <img className="embalagem-img" src={require(`./../${emb.img_link}`)} />
        <NavLink className="product-link" to={emb.shop_link} target="_blank">Comprar online</NavLink>
      </div>
    );
  });

  return (
    <>
      <Header />
      <div id="page-content">
        <div id="product-content">
          <div id="descricao"> <h3> {info.descricao} </h3> </div>
          <div id="sabor"> <span className="varejo-descricao">Sabor:</span> {info.sabor} </div>
          <div id="fabricante"> <span className="varejo-descricao">Fabricante:</span> {info.fabricante} </div>
          <div id="materia-prima">
            <div id="composicao"> <span className="varejo-descricao">Composição:</span> {info.composicao} </div>
            <div id="niveis-garantia">
              <span className="varejo-descricao">Niveis de garantia:</span>
              <table>
                <tbody>
                  {nvs_garantia}
                </tbody>
              </table>
            </div>
          </div>
          <div id="embalagens">
            <h4>Disponivel nas embalagens</h4>
            <div id="embalagens-imgs">
              {embalagens}
            </div>
          </div>
        </div>
      </div>
      {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
      <Footer />
    </>
  );
}